import styled from '@emotion/styled';
import { Logo } from '../Logo/Logo';

export const Banner = () => {
    return (
        <Container>
            <Logo />
        </Container>
    );
};

const Container = styled('div')({ background: '#FFFFFF', height: 350, textAlign: 'center' });
