import styled from '@emotion/styled';
import { Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material';
import React from 'react';

export const FollowUs = () => {
    return (
        <Container>
            <Title>Suivez-nous</Title>
            <Content>
                {/* <SocialItem href="/">
                    <Twitter />
                </SocialItem> */}
                <SocialItem href="https://www.facebook.com/Mifac-Mayotte-Ing%C3%A9nierie-Formation-Audit-et-Conseil-102294979178537">
                    <Facebook />
                </SocialItem>
                {/* <SocialItem href="/">
                    <Instagram />
                </SocialItem> */}
                {/* <SocialItem href="/">
                    <LinkedIn />
                </SocialItem> */}
            </Content>
        </Container>
    );
};

const Container = styled('div')({ display: 'flex', flexDirection: 'column' });
const Title = styled('h2')({ fontSize: 49, color: '#FFFFFF', marginBottom: 30 });
const Content = styled('div')({ display: 'flex', justifyContent: 'space-between', gap: 12 });

const SocialItem = ({ href, children }: React.AnchorHTMLAttributes<any>) => {
    const Container = styled('a')({
        width: 40,
        height: 40,
        color: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        background: '#016db5',
        '&:hover': { background: '#016db5' },
    });
    return (
        <Container href={href} target="_blank" rel="noopener noreferrer">
            {children}
        </Container>
    );
};
