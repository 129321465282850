import React from 'react';
import { AppLayout } from '../templates/AppLayout';
import { Container, Header, Li, Ul } from '../formations/FormationLayout';
import { BackNavigation } from '../templates/BackNavigation';
import styled from '@emotion/styled';

export default function MentionsLegalesPage() {
    return (
        <AppLayout>
            <Container>
                <Header>
                    <BackNavigation>MENTIONS LÉGALES</BackNavigation>
                </Header>
                <Content>
                    <Section title="1. Présentation du site">
                        <Text>
                            Le site internet <SiteName /> est la propriété de la société de : SARL Mayotte
                            <br />
                            <strong>Ingénierie Formation Audit et Conseil (MIFAC)</strong> <br />
                            Route Nationale de Sada (Tsararano) – 97660
                            <br />
                            Dembéni Siret 850 656 653 00019
                            <br />
                            Téléphone : 02 69 61 21 25
                            <br />
                            Créateur : 3S Informatique
                            <br />
                            Responsable publication : ARCHADI Anfifa – contact@mifac-ofpca.fr
                        </Text>
                    </Section>

                    <Section title="2. Conditions générales d’utilisation du site">
                        <Text>
                            L’utilisation du site <SiteName /> implique l’acceptation pleine et entière des conditions
                            générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles
                            d’être modifiées ou complétées à tout moment, les utilisations du <SiteName /> sont donc
                            invités à les consulter de manière régulière.
                        </Text>
                        <Text>
                            Le site est normalement accessible à tout moment aux utilisateurs. Une interruption pour
                            raison de maintenance technique peut être toutefois décidée par
                            <SiteName />, qui s’efforcera alors de communiquer préalablement aux utilisateurs les dates
                            et heures de l’intervention.
                        </Text>
                        <Text>
                            Le site <SiteName /> est mis à jour par ARCHADI Anfifa. De la même façon, les mentions
                            légales peuvent êtres modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur
                            qui y est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.
                        </Text>
                    </Section>

                    <Section title="3. Crédits photographiques">
                        <Text>
                            Les images utilisées pour la réalisation de ce site web <SiteName />
                            proviennent de SARL MIFAC, Adobe Stock..
                        </Text>
                    </Section>

                    <Section title="4. Description des services fournis">
                        <Text>
                            Le site <SiteName /> propose des informations aussi précises que possible. Toutefois, il ne
                            pourra être tenu responsable des omissions, des inexactitudes et des carences dans la mise à
                            jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces
                            informations
                        </Text>
                        <Text>
                            Tous les informations indiquées sur le site <SiteName /> sont données à titre indicatif, et
                            sont susceptibles d’évoluer. Par ailleurs, les renseignements figurant sur le site{' '}
                            <SiteName /> ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été
                            apportées depuis leur mise en ligne
                        </Text>
                    </Section>

                    <Section title="5. Limitations contractuelles sur les données techniques">
                        <Text>
                            Le site utilise la technologie JavaScript. Le site Internet ne pourra être tenu responsable
                            de dommages matériels liés à l’utilisation du site. De plus, l’utilisateur du site s’engage
                            à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un
                            navigateur de dernière génération mis-à-jour
                        </Text>
                    </Section>

                    <Section title="6. Propriété intellectuelle et contrefaçons">
                        <Text>
                            SARL MIFAC est propriétaire des droits de propriété intellectuelle ou détient les droits
                            d’usage sur tous les éléments accessibles sur le site, notamment les textes, images,
                            graphismes, logo, icônes, sons, logiciels, vidéos.
                        </Text>
                        <Text>
                            Toute reproduction, représentation, modification, publication, adaptation de tout ou partie
                            des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf
                            autorisation écrite préalable de : SARL MIFAC.
                        </Text>
                        <Text>
                            Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient
                            sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux
                            dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
                        </Text>
                    </Section>

                    <Section title="7. Limitations de responsabilité">
                        <Text>SARL MIFAC ne pourra être tenue responsable :</Text>
                        <Ul>
                            <Li>
                                Des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès
                                au site <SiteName />, et résultant soit de l’utilisation d’un matériel ne répondant pas
                                aux spécifications indiquées au point 4, soit de l’apparition d’un bug ou d’une
                                incompatibilité
                            </Li>
                            <Li>
                                Des dommages indirects (tels par exemple qu’une perte de marché ou perte de chance)
                                consécutifs à l’utilisation du site <SiteName />
                            </Li>
                            <Li>
                                Des espaces interactifs (possibilité de poser des questions dans l’espace contact) sont
                                à la disposition des utilisateurs.
                            </Li>
                        </Ul>
                        <Text>
                            SARL MIFAC de supprimer sans mise en demeure préalable, tout contenu déposé dans cet espace
                            qui contreviendrait à la législation applicable en France, en particulier aux dispositions
                            relatives à la protection des données. Le cas échéant, SARL MIFAC se réserve également la
                            possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur,
                            notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique,
                            quel que soit le support utilisé (texte, photographie…).
                        </Text>
                    </Section>
                    <Section title="8. Limitations de responsabilité">
                        <Text>
                            En France, les données personnelles sont notamment protégées par la loi n° 2018-493 du 20
                            juin 2018, relative à la protection des données personnelles du Code pénal. À l’occasion de
                            l’utilisation du site <SiteName />, peuvent être recueillies : l’URL des liens par
                            l’intermédiaire desquels l’utilisateur a accédé au site <SiteName /> , le fournisseur
                            d’accès de l’utilisateur, l’adresse de protocole Internet (IP) de l’utilisateur.
                        </Text>
                        <Text>
                            En tout état de cause <SiteName /> ne collecte des informations personnelles relatives à
                            l’utilisateur que pour le besoin de certains services proposés par le
                            <SiteName />. L’utilisateur fournit ces informations en toute connaissance de cause,
                            notamment lorsqu’il procède par lui-même à leur saisie. Il est alors précisé à l’utilisateur
                            du site <SiteName /> l’obligation ou non de fournir ces informations.
                        </Text>
                        <Text>
                            Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978 dite "Informatique et
                            Libertés", modifiée par la loi n° 2018-493 du 20 juin 2018, tout utilisateur dispose d’un
                            droit d’accès, de rectification et d’opposition aux données personnelles le concernant, en
                            effectuant sa demande écrite et signée, accompagnée d’une copie du titre d’identité avec
                            signature du titulaire de la pièce, en précisant l’adresse à laquelle la réponse doit être
                            envoyée.
                        </Text>
                        <Text>
                            Aucune information personnelle de l’utilisateur du site <SiteName /> n’est publiée à l’insu
                            de l’utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des
                            tiers. Les bases de données sont protégées par les dispositions de la loi du 1er juillet
                            1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des
                            bases de données.
                        </Text>
                    </Section>

                    <Section title="9. Liens hypertextes et cookies">
                        <Text>
                            La navigation sur le site <SiteName /> est susceptible de provoquer l’installation de
                            cookie(s) sur l’ordinateur de l’utilisateur. Un cookie est un fichier de petite taille, qui
                            ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations
                            relatives à la navigation d’un ordinateur sur un site. Les données ainsi obtenues visent à
                            faciliter la navigation ultérieure sur le site, et ont également vocation à permettre
                            diverses mesures de fréquentation.
                        </Text>
                        <Text>
                            Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains
                            services. L’utilisateur peut toutefois configurer son ordinateur de la manière suivante,
                            pour refuser l’installation des cookies :
                        </Text>
                        <Text>
                            <Ul>
                                <Li>
                                    <strong>Sous Internet Explorer :</strong> onglet outil (pictogramme en forme de
                                    rouage en haut a droite) / options internet. Cliquez sur Confidentialité et
                                    choisissez Bloquer tous les cookies. Validez sur Ok.
                                </Li>
                                <Li>
                                    <strong>Sous Edge :</strong>
                                    {
                                        'Icône Autres actions > Paramètres > Paramètres avancés. Sélectionnez Affichez les paramètres avancés. Rubrique Confidentialité et services > Cookies, sélectionnez Bloquer les cookies tiers uniquement) ou Bloquer tous les cookies.'
                                    }
                                </Li>
                                <Li>
                                    <strong>Sous Firefox :</strong> en haut de la fenêtre du navigateur, cliquez sur le
                                    bouton Firefox, puis aller dans l’onglet Options. Cliquer sur l’onglet Vie privée.
                                    Paramétrez les Règles de conservation sur : utiliser les paramètres personnalisés
                                    pour l’historique. Enfin décochez-la pour désactiver les cookies.
                                </Li>
                                <Li>
                                    <strong>Sous Safari :</strong> Cliquez en haut à droite du navigateur sur le
                                    pictogramme de menu (symbolisé par un rouage). Sélectionnez Paramètres. Cliquez sur
                                    Afficher les paramètres avancés. Dans la section « Confidentialité », cliquez sur
                                    Paramètres de contenu. Dans la section « Cookies », vous pouvez bloquer les cookies.
                                </Li>
                                <Li>
                                    <strong>Sous Chrome :</strong> Cliquez en haut à droite du navigateur sur le
                                    pictogramme de menu (symbolisé par trois lignes horizontales). Sélectionnez
                                    Paramètres. Cliquez sur Afficher les paramètres avancés. Dans la section «
                                    Confidentialité », cliquez sur préférences. Dans l’onglet « Confidentialité », vous
                                    pouvez bloquer les cookies.
                                </Li>
                            </Ul>
                        </Text>
                    </Section>

                    <Section title="10. Droit applicable et attribution de juridiction">
                        <Text>
                            Tout litige en relation avec l’utilisation du site <SiteName /> est soumis au droit
                            français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Paris
                            Les principales lois concernées :
                            <Ul>
                                <Li>
                                    Loi n° 78-87 du 6 janvier 1978, notamment modifiée par la loi n° 2004-801 du 6 août
                                    2004 relative à l’informatique, aux fichiers et aux libertés.
                                </Li>
                                <Li>Loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique.</Li>
                            </Ul>
                        </Text>
                    </Section>
                    <Section title="11. Lexique">
                        <Text>
                            Utilisateur : Internaute se connectant, utilisant le site susnommé. Informations
                            personnelles : « les informations qui permettent, sous quelque forme que ce soit,
                            directement ou non, l’identification des personnes physiques auxquelles elles s’appliquent »
                            (article 4 de la loi n° 78-17 du 6 janvier 1978).
                        </Text>
                    </Section>
                </Content>
            </Container>
        </AppLayout>
    );
}

const Content = styled('div')({ display: 'flex', flexDirection: 'column' });
const Text = styled('p')({ color: '#444', fontSize: 14 });

type SectionProps = { title: string };
const Section = ({ title, children }: React.PropsWithChildren<SectionProps>) => {
    const Container = styled('div')({ display: 'flex', flexDirection: 'column' });
    const Title = styled('h3')({ fontWeight: 'normal', fontSize: 23 });
    return (
        <Container>
            <Title>{title}</Title>
            {children}
        </Container>
    );
};

const SiteName = () => {
    const Container = styled('span')({ width: 'auto' });
    return <Container>mifac-ofpca.fr</Container>;
};
