/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export type EnhancedViewState<T extends {}> = Partial<T> & Partial<{ loading: boolean; modified: boolean; saving: boolean; busy: boolean, initialized: boolean }>;

export interface ViewState<TState extends {}> {
    readonly initialState: EnhancedViewState<TState>;
    readonly state: EnhancedViewState<TState>;
    readonly getState: () => EnhancedViewState<TState>;
    readonly onChange: (field: keyof EnhancedViewState<TState>, value: any, afterUpdate?: (newState: EnhancedViewState<TState>) => void) => void;
    readonly update: (values: Partial<EnhancedViewState<TState>>, afterUpdate?: (newState: EnhancedViewState<TState>) => void) => void;

    readonly resetState: (values?: Partial<EnhancedViewState<TState>>, afterUpdate?: (newState: EnhancedViewState<TState>) => void) => void;
}

export const useViewState = <TState extends {}>(initialState: EnhancedViewState<TState> = {}): ViewState<TState> => {

    const refState = React.useRef<EnhancedViewState<TState>>(initialState);
    const [state, setState] = React.useState<EnhancedViewState<TState>>(refState.current);

    const getState = (): EnhancedViewState<TState> => refState.current;

    const update = React.useCallback((values: Partial<EnhancedViewState<TState>>, afterUpdate?: (newState: EnhancedViewState<TState>) => void): void => {
        setState((prevState) => {
            const newState: EnhancedViewState<TState> = { ...prevState, ...values };
            refState.current = newState
            setTimeout(() => afterUpdate?.(newState));
            return newState;
        });
    }, []);

    const onChange = React.useCallback((field: keyof EnhancedViewState<TState>, value: any, afterUpdate?: (newState: EnhancedViewState<TState>) => void): void => {
        const values = { [field]: value, modified: true } as Partial<EnhancedViewState<TState>>;
        update(values, afterUpdate);
    }, []);

    const resetState = React.useCallback((values: Partial<EnhancedViewState<TState>> = {}, afterUpdate?: (newState: EnhancedViewState<TState>) => void): void => {
        setState((prevState) => {
            const newState: EnhancedViewState<TState> = { ...initialState, ...values };
            refState.current = newState
            setTimeout(() => afterUpdate?.(newState));
            return newState;
        });
    }, []);

    return { initialState, state, getState, onChange, update, resetState };
}