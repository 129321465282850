import styled from '@emotion/styled';
import React from 'react';
import { FollowUs } from './FollowUs';
import { Adress } from './Adress/Adress';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../Router';
import { LogoQualiopi } from './LogoQualiopi/LogoQualiopi';

export const SiteMap = () => {
    return (
        <Container id="SiteMap">
            <Content>
                <Left>
                    <Formations title="Formations">
                        <MenuLink to={AppRoutes.FormationBpjepsAPT}>
                            {
                                "- BPJEPS APT : Brevet Professionnel de la Jeunesse de l'Education Populaire et du Sport mention Activités Physiques Pour Tous (Niveau BAC)"
                            }
                        </MenuLink>
                        <MenuLink to={AppRoutes.FormationBpjepsLTP}>
                            {
                                "- BPJEPS LTP : Brevet Professionnel de la Jeunesse de l'Education Populaire et du Sport mention Loisirs Tous Publics (Niveau BAC)"
                            }
                        </MenuLink>
                        <MenuLink to={AppRoutes.FormationCpjeps}>
                            {
                                "- CPJEPS : Certificat Professionnel de la Jeunesse, de l'Education Populaire et du Sport - Animateur d'Activités et de Vie Quotidienne (Niveau CAP)"
                            }
                        </MenuLink>
                        <MenuLink to={AppRoutes.FormationDejeps}>
                            {
                                "- DEJEPS : Diplômes d'Etat de la Jeunesse, de l'Education Populaire et du Sport (Niveau BAC +2)"
                            }
                        </MenuLink>
                    </Formations>
                    <LogoQualiopi />
                </Left>
                <Right>
                    <FollowUs />
                    <Adress style={{ marginTop: 112 }} />
                </Right>
            </Content>
        </Container>
    );
};

const mifacColors = {
    Orange: '#f07829',
    Blue: '#004aad',
};
const Container = styled('div')((prop) => ({ background: mifacColors.Blue, padding: '60px 0px' }));

const Content = styled('div')({
    display: 'flex',
    marginRight: 16,
    marginLeft: 16,
    justifyContent: 'space-between',
    '@media (max-width: 800px)': { flexDirection: 'column' },
});

const Left = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 800px)': { alignItems: 'center' },
});

const Right = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 32,
    width: '33%',
    '@media (max-width: 800px)': { width: '100%', padding: 0 },
});

const Formations = ({ title, style, children }: React.PropsWithChildren<React.HtmlHTMLAttributes<any>>) => {
    const Container = styled('div')({
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        color: '#FFFFFF',
        '@media (max-width: 800px)': { display: 'none' },
    });

    const Header = styled('div')({ fontWeight: 600, fontSize: 14 });
    const Body = styled('ul')({
        marginTop: 20,
        marginBottom: 30,
        display: 'flex',
        flexDirection: 'column',
        listStyle: 'none',
        gap: 10,
        padding: 0,
    });
    const items = React.Children.toArray(children);

    return (
        <Container>
            <Header>{title}</Header>
            <Body>
                {React.Children.map(items, (child, index) => {
                    return <li>{child}</li>;
                })}
            </Body>
        </Container>
    );
};

const MenuLink = styled(Link)((prop) => ({
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    color: '#FFFFFF',
    fontSize: 14,
    '&:hover': { color: mifacColors.Orange, opacity: 1 },
}));
