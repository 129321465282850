'use client';
import { ArrowForward } from '@mui/icons-material';
import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../Router';

export default function MenuFormations() {
    return (
        <Menu className="menu">
            <MenuLink to={AppRoutes.FormationBpjepsAPT}>
                <ArrowForward />
                {
                    "BPJEPS APT : Brevet Professionnel de la Jeunesse de l'Education Populaire et du Sport mention Activités Physiques Pour Tous (Niveau BAC)"
                }
            </MenuLink>
            <MenuLink to={AppRoutes.FormationBpjepsLTP}>
                <ArrowForward />
                {
                    "BPJEPS LTP : Brevet Professionnel de la Jeunesse de l'Education Populaire et du Sport mention Loisirs Tous Publics (Niveau BAC)"
                }
            </MenuLink>
            <MenuLink to={AppRoutes.FormationCpjeps}>
                <ArrowForward />
                {
                    "CPJEPS : Certificat Professionnel de la Jeunesse, de l'Education Populaire et du Sport - Animateur d'Activités et de Vie Quotidienne (Niveau CAP)"
                }
            </MenuLink>
            <MenuLink to={AppRoutes.FormationDejeps}>
                <ArrowForward />
                {"DEJEPS : Diplômes d'Etat de la Jeunesse, de l'Education Populaire et du Sport (Niveau BAC +2)"}
            </MenuLink>
        </Menu>
    );
}

const mifacColors = { Orange: '#f07829', Blue: '#004aad' };
const Menu = styled('div')({
    display: 'none',
    width: 500,
    flexDirection: 'column',
    background: mifacColors.Blue,
    position: 'absolute',
    padding: 0,
    top: 60,
});

const MenuLink = styled(Link)({
    minHeight: 42,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    padding: 16,
    color: '#FFFFFF',
    '&:hover': { color: mifacColors.Blue, background: '#FFFFFF' },
});
