import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';

export const BackNavigation = ({ children }: React.PropsWithChildren<any>) => {
    return (
        <Container>
            <ButtonLink to="/">Accueil</ButtonLink>
            {'> '}
            {children}
        </Container>
    );
};

const Container = styled.div((props) => ({
    display: 'flex',
    fontSize: 10,
    alignItems: 'center',
    textTransform: 'uppercase',
    lineLeight: 1.8,
    fontWeight: 'bold',
    gap: 4,
    //@ts-ignore
    color: props.theme.mifacColors.Blue,
}));

const ButtonLink = styled(Link)((props) => ({
    //@ts-ignore
    '&:hover': { color: props.theme.mifacColors.Orange },
    textDecoration: 'none',
    //@ts-ignore
    color: props.theme.mifacColors.Blue,
}));
