import styled from '@emotion/styled';
import React from 'react';

import logo from './logo_qualiopi_mention.jpg';

export const LogoQualiopi = ({ style }: React.ImgHTMLAttributes<any>) => {
    return (
        <Container>
            <Img src={logo} alt="logo" style={style} />
            <Link style={style} href="/docs/mifac-certificat_qualiopi.pdf" target="_blank" rel="noopener noreferrer">
                Certificat à télécharger
            </Link>
        </Container>
    );
};

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    background: '#FFF',
    width: 'fit-content',
    paddingBottom: 32,
});

const Img = styled('img')({
    maxWidth: 655,
    width: 'auto',
    objectFit: 'contain',
    '@media (max-width: 1050px)': { maxWidth: 555 },
    '@media (max-width: 850px)': { maxWidth: 500 },
    '@media (max-width: 700px)': { maxWidth: 455 },
});
const Link = styled('a')({ textDecoration: 'none', textAlign: 'center' });
