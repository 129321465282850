import { Stack } from '@mui/material';

import { AppLayout } from '../../templates/AppLayout';
import {
    AccessibilitySection,
    BtnDownload,
    ButtonDownloadFormationMifac,
    ButtonDownloadRGInterieurMifac,
    DateUpdate,
    FormationContainer,
    FormationContent,
    FormationLayout,
    FormationProgramme,
    FormationProgrammeSection,
    FormationRate,
    FormationTimeSection,
    FormmationHeader,
    Li,
    Section,
    SectionDelaisAccess,
    Strong,
    Text,
    Ul,
    Underline,
} from '../FormationLayout';
import thumbnail_indicateur_CPJEPS from './thumbnail_indicateur_CPJEPS.png';

export default function CpjepsPage() {
    return (
        <AppLayout>
            <FormationContainer>
                <FormmationHeader>CPJEPS – Animateur d’Activités et de Vie Quotidienne</FormmationHeader>
                <SubtitleCpjeps />
                <FormationRate src={thumbnail_indicateur_CPJEPS} />
                <FormationLayout style={{ marginTop: 32 }}>
                    <FormationProgramme>
                        <FormationProgrammeSection title="Métier visé">
                            <Text>
                                La formation CPJEPS valide des compétences dans l’encadrement des activités éducatives,
                                sociales et culturelles dans la limite des cadres réglementaires.
                            </Text>
                            <Text>
                                Le diplôme permet à son titulaire d’animer des d’activités en autonomie dans diverses
                                structures accueillant des publics variés allant de la petite enfance au public adulte.
                            </Text>
                            <Text>
                                Il est le responsable de ses projets d’activités du point de vue de leurs mises en
                                œuvre, de leurs mobilisations en ressources et de la sécurité des tiers et des personnes
                                prises en charge dans les projets activités.
                            </Text>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Exemples d’emplois préparés">
                            <Ul>
                                <Li>Animateur enfance jeunesse</Li>
                                <Li>Animateur accueil de loisirs</Li>
                                <Li>Animateur en accueil de loisirs périscolaires</Li>
                                <Li>Animateur en séjours de vacances</Li>
                                <Li>Animateur socioéducatif ou socioculturel</Li>
                            </Ul>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Secteur d’emploi">
                            <Text>
                                <Li> Accueils collectifs des mineurs (ACM)</Li>
                                <Li> Structures de la petite enfance</Li>
                                <Li>
                                    Opérateurs de loisirs organisant des activités d’animation socioéducative ou
                                    socioculturelle, Collectivités territoriales, …
                                </Li>
                            </Text>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Compétences">
                            <Ul>
                                <Li>
                                    Connaitre et comprendre l’environnement professionnel, institutionnel et social de
                                    l’action d’animation
                                </Li>
                                <Li>
                                    Prévenir et garantir les aspects sécuritaires (physique, matériel, psychologique
                                    etc…) des tiers et des publics pris en charge dans le cadre des activités
                                    d’animation
                                </Li>
                                <Li>
                                    Constituer et instaurer des relations de travail et une bonne communication avec les
                                    publics accueillis et les partenaires
                                </Li>
                                <Li>
                                    Suggérer des activités de différentes natures en tenant compte des besoins, des
                                    intérêts, des capacités et des rythmes des publics ainsi que les particularités du
                                    territoire d’intervention
                                </Li>
                                <Li>
                                    Favoriser l’élaboration de la posture professionnelle d’animateur d’activités et de
                                    vie quotidienne et d’une éthique citoyenne
                                </Li>
                            </Ul>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Objectifs & Contenus">
                            <Text>
                                La formation s’appuie sur des stages en entreprise et s’articule autour de 3 blocs de
                                compétences qui intègrent 4 unités capitalisables (UC) et un module transversal dédié au
                                suivi des parcours formatifs des stagiaires. A l’issue de la formation, le stagiaire
                                sera capable de :
                            </Text>
                            <Section title="Bloc de compétence 1">
                                <Text>
                                    Être capable de participer au projet et à la vie de la structure (UC1) et Animer les
                                    temps de vie quotidienne de groupes (UC2).
                                </Text>
                                <Ul style={{ gap: 12 }}>
                                    <Li>
                                        <Strong>Contenus UC 1 :</Strong> Compréhension des contextes d’intervention ;
                                        Identification des acteurs et de leurs particularités ; Coéducation dans le
                                        champ d’activité et de l’éducation populaire ; Principes des travaux
                                        collaboratifs et la mise en commun le travail à l’écrit dans le métier ;
                                        Utilisation des outils de communication
                                    </Li>

                                    <Li>
                                        <Strong>Contenus UC 2 :</Strong> Connaissance des caractéristiques des publics ;
                                        Notion d’ACCUEIL ; Compréhension des différents temps notamment les temps de
                                        transition dans les activités ; Notion sur les dynamiques de groupes
                                    </Li>
                                </Ul>
                            </Section>
                            <Section title="Bloc de compétence 2">
                                <Text>Être capable de concevoir des activités en direction d’un groupe.</Text>
                                <Ul>
                                    <Li>
                                        <Strong>Contenus UC3 :</Strong> Différents types de projet et liaison avec les
                                        séances et séquences d’animation ; Séquences d’animation et progression
                                        pédagogique en fonction du public ; Fiches séances et méthode d’évaluer son
                                        action ; Connaissance de la réglementation pour animer en sécurité.
                                    </Li>
                                </Ul>
                            </Section>
                            <Section title="Bloc de compétences 3">
                                <Text>Être capable d’animer en sécurité dans le champ d’activité</Text>
                                <Ul>
                                    <Li>
                                        <Strong>Contenus UC 4 :</Strong> Techniques d’animation autour des jeux
                                        scientifiques et techniques ; Sciences et leur histoire à travers une démarche
                                        expérimentale ; Techniques d’animation autour des activités d’expression et des
                                        activités physiques ; Notion de développement durable et d’économie circulaire à
                                        travers les activités ; Comment animer en fonction du public et avec des
                                        méthodes pédagogiques adaptées.
                                    </Li>
                                </Ul>
                            </Section>
                            <Section title="Module transversal">
                                <Text>Être capable d’accompagner les parcours de formation.</Text>
                                <Ul>
                                    Du positionnement à l’admission en formation – progressions pédagogiques
                                    centre/entreprise ; déploiement du dispositif de certification ; bilans d’étape et
                                    projet professionnel…
                                </Ul>
                            </Section>

                            <Text>
                                <Underline>Bon à savoir :</Underline> Possibilité d’individualiser le parcours de
                                formation par la voie de certification indépendante des unités capitalisables.
                            </Text>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Publics concernés">
                            <Text>
                                La formation est ouverte à toute personne souhaitant s’inscrire dans une démarche de
                                professionnalisation en lien avec un projet professionnel dans l’encadrement des
                                activités socioéducatives, culturelles et de loisirs sportifs à finalité éducative ou
                                sociale.
                            </Text>
                            <Text>
                                Sont ciblés par la formation : demandeurs d’emploi, salariés désirant se qualifier,
                                personnes en reconversion professionnel, salariés en contrat de professionnalisation,
                                volontaires en service civique, personnes inscrites à titre individuel.{' '}
                            </Text>

                            <Section title="Prérequis">
                                <Ul>
                                    <Li>Être âgé de 17 ans révolus à la date d’entrée en formation</Li>
                                    <Li>
                                        Être titulaire d’une des attestations suivante aux premiers secours (PSC1, AFPS,
                                        PSE, AFGSU, SST en cours de validité)
                                    </Li>
                                </Ul>
                            </Section>
                            <Section title="Modalités d’accès">
                                <Ul>
                                    <Li>
                                        Transmettre un dossier de candidature complet avec les pièces justificatives
                                        demandées avant la date limite des inscriptions
                                    </Li>
                                    <Li>Satisfaire l’ensemble des épreuves de sélection</Li>
                                    <Li>
                                        Avoir un niveau d’écriture, de lecture et de compréhension idéalement de
                                        troisième
                                    </Li>
                                    <Li>Avoir un casier judiciaire vierge (aucune condamnation-infraction pénale)</Li>
                                </Ul>
                            </Section>

                            <SectionDelaisAccess />

                            <Section title="Tests de sélection">
                                <Ul>
                                    <Li>Epreuve écrite individuelle (1h30)</Li>
                                    <Li>Epreuve orale : entretien de motivation (30 minutes)</Li>
                                </Ul>
                            </Section>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Méthodes mobilisées">
                            <Text>
                                La formation est construite à partir des méthodes d’éducation active alternant cours
                                théoriques, mises en situations professionnelles et réflexions collectives. Ici nous
                                nous appuyons sur :
                            </Text>
                            <Ul>
                                <Li>Les apports théoriques et techniques</Li>
                                <Li>Les études de cas et travaux en petits ou grands groupes</Li>
                                <Li>Les vécus expérientiels et les projets des apprenants</Li>
                                <Li>Les rencontres thématiques avec des professionnels</Li>
                                <Li>
                                    Les suivis individualisés à la production des écrits et dossiers de certification
                                </Li>
                                <Li> Les moments d’échange entre le stagiaire, le tuteur et référent pédagogique</Li>
                            </Ul>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Ressources mobilisées">
                            <Section title="Moyens pédagogiques">
                                <Ul>
                                    <Li>
                                        Une salle de formation équipée des tables, chaises, tableaux blanc et vidéos
                                        projecteur
                                    </Li>
                                    <Li>
                                        Une salle dédiée à un espace de ressource-coworking avec un fond documentaire et
                                        d’information (ouvrages et revues) spécialisés dans le champ professionnel de
                                        l’animation
                                    </Li>
                                    <Li>
                                        Un fond de matériels techniques nécessaires pour les besoins en quantité et en
                                        qualité de la formation
                                    </Li>
                                    <Li>
                                        A proximité : un terrain de grand jeu, un plateau sportif polyvalent et des
                                        structures d’éducation populaire (MJC, MPT, etc…)
                                    </Li>
                                    <Li>
                                        Une équipe de formateurs et d’intervenants spécialisés dans diverses thématiques
                                        dispensées dans le cadre de la formation
                                    </Li>
                                    <Li>
                                        Un double accompagnement assuré par un(e) référent(e) pédagogique et un tuteur
                                        de stage pratique
                                    </Li>
                                </Ul>
                            </Section>
                            <Section title="Autres moyens">
                                <Li>Des bureaux administratifs (accueil et direction)</Li>
                                <Li>Un espace de convivialité et des restaurants à proximité</Li>
                                <Li>Des sanitaires accessibles pour les personnes à mobilité réduite</Li>
                                <Li>Possibilité de stationnement gratuit aux environs du centre de formation</Li>
                                <Li>
                                    Accès wifi sécurisé, imprimante et d’autres matériels à disposition des apprenants
                                </Li>
                            </Section>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Modalités d’évaluation ">
                            <Section title="Conditions d’obtention du diplôme">
                                <Text>
                                    Le CPJEPS - AAVQ s’obtient par la capitalisation des 4 unités capitalisables. Elle
                                    se fait de manière ponctuelle, c’est-à-dire pas d’examen final, mais avec un
                                    processus de certification en centre et en entreprise échelonné sur la durée du
                                    parcours de formation.
                                </Text>
                                <Text>
                                    Un programme de rattrapage est prévu en cas de non-satisfaction à la certification
                                    initiale, soit après quelques semaines, soit au cours d’une session de rattrapage
                                    mise en place par l’organisme.
                                </Text>
                                <Text>
                                    Des validations partielles sont par ailleurs possibles pour les stagiaires n’ayant
                                    pas obtenus le diplôme afin de les permettre de garder le bénéfice des unités
                                    capitalisables en vue d’une éventuelle réinscription à la formation.
                                </Text>
                            </Section>
                            <Section title="Conditions de certification du diplôme">
                                <Text>
                                    Celles-ci sont mentionnées dans l’annexe III de l’arrêté du 30 juillet 2019 portant
                                    sur les épreuves certificatives des unités capitalisables du certificat
                                    professionnel, de la jeunesse, de l’éducation populaire et du sport - mention «
                                    animateur d’activités et de vie quotidienne ». Les épreuves certificatives se
                                    déroulent dans les conditions prévues à l’article A. 212-26 du code du sport.
                                </Text>
                                <Text>
                                    Les unités capitalisables constitutives de la mention « animateur d'activités et de
                                    vie quotidienne » du certificat professionnel, de la jeunesse, de l’éducation
                                    populaire et du sport sont attribuées selon le référentiel de certification dont
                                    l’acquisition est contrôlée par les épreuves certificatives suivantes : « production
                                    de documents écrits personnel ; entretien oral ; mise en situation professionnelle
                                    ».
                                </Text>
                            </Section>
                            <Section title="Validation des acquis de l’expérience (VAE)">
                                <Text>Pour le moment, l’organisme de formation ne propose pas un accompagne</Text>
                            </Section>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Financement de la formation">
                            <Section title="Comment financer votre formation ?">
                                <Text>
                                    Diverses sources de financement sont possibles pour le CPJEPS - AAVQ. Elles
                                    dépendent de votre statut de candidat et peuvent-être prises en charge dans le cadre
                                    :
                                </Text>
                                <Ul>
                                    <Li>D’un financement à votre propre initiative</Li>
                                    <Li>D’un financement de votre Organisme collecteur (AKTO Mayotte)</Li>
                                    <Li>D’un plan de formation de votre employeur</Li>
                                    <Li>De votre Compte Personnel de Formation (CPF)</Li>
                                    <Li>Du plan de développement de compétences (PDC)</Li>
                                    <Li>
                                        D’un financement de votre Conseil départemental. A ce titre, 12 parcours sont
                                        pris en charge gratuitement pour les demandeurs d’emploi.{' '}
                                    </Li>
                                </Ul>
                            </Section>
                            <Section title="Tarif de la formation">
                                <Text>
                                    Pour connaitre le détail de nos tarifs, veuillez nous contacter au 02 69 61 21 25 ou
                                    nous écrire au contact@mifac-ofpca.fr Un devis détaillant les tarifs appliqués par
                                    unité capitalisable vous sera par ailleurs proposé.
                                </Text>
                            </Section>
                        </FormationProgrammeSection>
                        <FormationProgrammeSection title=" Poursuite du parcours">
                            <Text>
                                L’obtention du diplôme CPJEPS - AAVQ vous donne l’opportunité d’être opérationnel en
                                tant qu’animateur d’activités dans une structure d’animation et/ou d’entreprendre une
                                formation supérieure telle que le BPJEPS dans les métiers de l’animation ou du sport.
                            </Text>
                        </FormationProgrammeSection>
                    </FormationProgramme>
                    <FormationContent>
                        <BtnDownload href="/docs/formations/cpjeps/fiche_programme_CPJEPS-AAVQ.pdf">
                            Fiche programme CPJEPS AAVQ
                        </BtnDownload>
                        <BtnDownload href="/docs/formations/cpjeps/dossier_inscription_cpjeps-aavq.pdf">
                            Dossier d’inscription 2024-2025
                        </BtnDownload>
                        <BtnDownload href="/docs/formations/cpjeps/depliant_CPJEPS_AAVQ_2024–2025.pdf">
                            Dépliant CPJEPS AAVQ 2024 – 2025
                        </BtnDownload>
                        <BtnDownload href="/docs/formations/cpjeps/calendrier-cpjeps_ aavq.pdf">
                            Calendrier prévisionnel 2024-2025
                        </BtnDownload>
                        <BtnDownload href="/docs/formations/cpjeps/plaquette_cpjeps-aavq.pdf">
                            Plaquette de formation CPJEPS-AAVQ
                        </BtnDownload>
                        <BtnDownload href="/docs/formations/cpjeps/tableaux_equivalence_cpjeps-aavq.pdf">
                            Tableaux équivalence CPJEPS-AAVQ
                        </BtnDownload>

                        <ButtonDownloadFormationMifac />
                        <ButtonDownloadRGInterieurMifac />

                        <FormationTimeSection />
                        <AccessibilitySection />
                        <DateUpdate />
                    </FormationContent>
                </FormationLayout>
            </FormationContainer>
        </AppLayout>
    );
}
export const SubtitleCpjeps = () => {
    return (
        <Stack style={{ marginTop: 24 }}>
            <Strong>Certificat Professionnel de la Jeunesse, de l’Education Populaire et du Sport (CPJEPS),</Strong>
            <Text style={{ lineHeight: '0px' }}>Mention Animateur d’Activités et de Vie Quotidienne (AAVQ)</Text>
            <Text style={{ lineHeight: '0px', marginTop: 16 }}>
                <Underline>Certificateur :</Underline> Ministère des Sports et des Jeux olympiques et paralympiques
            </Text>
            <Text style={{ lineHeight: '0px', marginTop: 16 }}>Code RNCP 32369 --- Enregistré le 26-02-2019</Text>
        </Stack>
    );
};
