import styled from '@emotion/styled';
import { AppLayout } from '../../templates/AppLayout';
import { Container, Header, Li, Text, Title, Ul } from '../../formations/FormationLayout';

import { BackNavigation } from '../../templates/BackNavigation';
import { AddressContact, GmalMailContact, TelContact } from '../../templates/Adress/Adress';

import map from './map.jpg';
export default function LieuDeFormationPage() {
    return (
        <AppLayout>
            <Container>
                <Header>
                    <BackNavigation>Lieu de formation</BackNavigation>
                    <Title>Lieu de formation</Title>
                </Header>
                <Body style={{ marginTop: 32, gap: 32 }}>
                    <Content data-col="left" style={{ justifyContent: 'center' }}>
                        <AddressContact style={{ marginBottom: 6 }} />
                        <TelContact style={{ marginBottom: 6 }} />
                        <Row>
                            <strong> Mail : </strong>
                            <GmalMailContact style={{ marginLeft: 6 }} />
                        </Row>
                    </Content>
                    <Content data-col="right">
                        <Map src={map} alt="map" />
                    </Content>
                </Body>
                <Content>
                    <Text>
                        Nos locaux sont accessibles pour tous les stagiaires et règlent une partie de la problématique
                        d’assiduité, d’absentéisme et de désistement de formation qui touchent les organismes basés sur
                        le Grand Mamoudzou pour cause d’embouteillages et de moyens de locomotion. Ils comprennent :
                    </Text>
                    <Ul>
                        <Li>Deux salles de formation d’une capacité d’accueil de 16 à 22 personnes</Li>
                        <Li>Une salle moyenne dédiée à un espace de ressource-coworking</Li>
                        <Li>Deux bureaux administratifs</Li>
                        <Li>
                            Un espace de restauration/convivialité (réfrigérateur, micro-onde, cafetière/bouilloire)
                        </Li>
                        <Li>
                            Des sanitaires/vestiaires accessibles pour les personnes à mobilité réduite ou en situation
                            de handicap
                        </Li>
                        <Li>
                            A proximité du site un terrain de grand jeu, un plateau sportif polyvalent, un parcours de
                            santé, un sentier de randonnée pédestre, des maisons des jeunes et de la culture,
                            associations d’éducation populaire
                        </Li>
                        <Li>Possibilité de stationnement gratuit aux environs</Li>
                    </Ul>
                </Content>
            </Container>
        </AppLayout>
    );
}

const Body = styled('div')({ display: 'flex', '@media (max-width: 800px)': { flexDirection: 'column' } });
const Content = styled('div')({ display: 'flex', flexDirection: 'column', marginBottom: 32 });
const Row = styled('div')({ display: 'flex' });

const Map = styled('img')({
    marginTop: 12,
    marginBottom: 24,
    width: 400,
    '@media (max-width: 800px)': { width: '100%' },
});
