import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import React from 'react';

declare module '@mui/material/styles' {
    interface AppTheme {
        mifacColors: {
            Orange: string;
            Blue: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        mifacColors: {
            Orange: string;
            Blue: string;
        };
    }
}

const theme = createTheme({
    mifacColors: {
        Orange: '#f07829',
        Blue: '#004aad',
    },
});
export const MuiTheme: React.FC<any> = ({ children }) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

//export const MifacColors = () => ({ Orange: '#f07829', Blue: '#004aad' });

const mifacColors = {
    Orange: '#f07829',
    Blue: '#004aad',
};
