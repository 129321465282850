import React from 'react';
import { AppLayout } from './templates/AppLayout';

import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { Logo } from './templates/Logo/Logo';
import { Banner } from './templates/Banner/Banner';

export default function Home() {
    return (
        <AppLayout>
            <Banner />
            {/* <Container>
                <VideoContainer>
                    <VideoBackground className="videoTag" autoPlay loop muted>
                        <source src="/assets/presentation.mov" type="video/mp4" />
                    </VideoBackground>
                    <VideoMask />
                </VideoContainer>
                <LogoContainer>
                    <Logo style={{ position: 'absolute', margin: 'auto' }} />
                </LogoContainer>
            </Container> */}
        </AppLayout>
    );
}

const Container = styled(Box)({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    background: '#004aad',
});
const VideoContainer = styled(Box)({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    background: '#004aad',
});
const VideoBackground = styled('video')({ width: '100vw', height: 600, position: 'relative' });
const VideoMask = styled(Box)({
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: 'rgba(10, 10, 10, 0.6)',
});

const LogoContainer = styled(Box)({
    width: '100%',
    position: 'absolute',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
