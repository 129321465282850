import { toast } from 'react-toastify';

type MailData = {
    lastName?: string;
    firstName?: string;
    phoneNumber?: string;
    email?: string;
    message?: string;
};
export class EmailService {
    private static POST_MAIL = 'https://api.emailjs.com/api/v1.0/email/send';

    public static sendMail = async (mailData: MailData) => {
        const data = {
            service_id: 'service_jqo2rkr',
            template_id: 'template_sk4uhk2',
            user_id: '2LiHn2Pn2y_tjkf0A',
            template_params: {
                last_name: mailData.lastName,
                first_name: mailData.firstName,
                phone_number: mailData.phoneNumber,
                email: mailData.email,
                message: mailData.message,
            },
        };
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        };
        try {
            const response = await fetch(this.POST_MAIL, requestOptions);
            console.log('sendMail', { response });
            toast.success('Votre réclamation a été envoyé');
            return Promise.resolve(true);
        } catch (error) {
            console.error(error);
            return Promise.resolve(false);
        }
    };
}
