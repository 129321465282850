import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { ToastContainer } from 'react-toastify';

import { AppRouter } from './app/Router';
import { MuiTheme } from './themes/Theme';

function App() {
    return (
        <MuiTheme>
            <AppRouter />
            <ToastContainer />
        </MuiTheme>
    );
}

export default App;
