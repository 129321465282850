import React from 'react';
import styled from '@emotion/styled';

import logoEIM from './logo_europe-in-mayotte.jpg';
import logoEU from './logo_european-union.jpg';
import logoMD from './logo_mayotte-department.png';
import logoMLM from './logo_mission-locale-mayotte.png';
import logoFranceTravail from './logo_france_travail.jpg';
import logoRF from './logo_republique-francaise-prefet-mayotte.jpg';
import logoAcademie from './logo_academie.jpg';
import logoAKTO from './logo_akto.png';
import logodRAJES from './logo_drajes.jpg';

export const Funders = () => {
    return (
        <Container id="Funders">
            <Content>
                <Title>Financeurs :</Title>
                <Grid>
                    <Img src={logoMD} alt="" />
                    <Img src={logoEIM} alt="" />
                    <Img src={logoEU} alt="" style={{ maxHeight: 125 }} />
                    <Img src={logoRF} alt="" style={{ maxHeight: 125 }} />
                    <Img src={logoAcademie} alt="" style={{ maxHeight: 125 }} />
                    <Img src={logodRAJES} alt="" style={{ maxHeight: 125 }} />
                    <Img src={logoFranceTravail} alt="" />
                    <Img src={logoMLM} alt="" style={{ maxHeight: 125 }} />
                    <Img src={logoAKTO} alt="" />
                </Grid>
            </Content>
        </Container>
    );
};
const Container = styled('div')({ background: '#FFFFFF', marginTop: 60 });

const Content = styled('div')({
    marginRight: 16,
    marginLeft: 16,
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 800px)': {},
});

const Title = styled('h5')({
    color: '#016db5',
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: 14,
    '@media (max-width: 800px)': {
        alignSelf: 'center',
    },
});

const Grid = styled('div')({
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 12,
});

const Img = styled('img')({ maxHeight: 100, objectFit: 'contain' });
