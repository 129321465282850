import styled from '@emotion/styled';
import React from 'react';

import logo from './logo.png';

export const Logo = ({ style }: React.ImgHTMLAttributes<any>) => {
    return <Img src={logo} alt="logo" style={style} />;
};

const Img = styled('img')({ width: 350, height: 350, objectFit: 'contain' });
