import styled from '@emotion/styled';
import { AppLayout } from '../../templates/AppLayout';
import { Container, Header, Text, Title } from '../../formations/FormationLayout';

import { BackNavigation } from '../../templates/BackNavigation';

export default function IndividualisationDesParcoursPage() {
    return (
        <AppLayout>
            <Container>
                <Header>
                    <BackNavigation>Notre modèle d’individualisation des parcours</BackNavigation>
                </Header>
                <Title>L’individualisation</Title>
                <Content>
                    <Text>
                        Tout d’abord, l’individualisation des parcours représente pour notre organisme le seul processus
                        capable d’organiser et d’encourager la réussite de nos formations. Elle vise la mise en œuvre
                        d’une démarche personnalisée de la formation. Ensuite, elle met à la disposition de l’apprenant
                        un ensemble de ressources et moyens pédagogiques nécessaires à son parcours et à ses situations
                        d’apprentissage.
                    </Text>
                    <Text>
                        Lors des entretiens d’admission à la formation, les acquis et les connaissances du stagiaire
                        sont d’emblés pris en compte dans les modalités pédagogiques. Ils sont explicités dans le
                        parcours formatif et dans l’expérience professionnelle du stagiaire dans lesquels le principe de
                        l’alternance intégrative est au cœur de notre démarche de formation. Ainsi, les situations de
                        stage pédagogique consolidant cette expérience favorisent l’approfondissement des compétences du
                        stagiaire.
                    </Text>
                    <Text>
                        Pour enrichir cette individualisation, nous avons un carnet d’adresses rempli des contacts de
                        structures, potentiellement possibles en capacité de recevoir des stagiaires dans les meilleures
                        conditions, qui nous permettent ainsi d’orienter et de conseiller ces derniers dans leur choix
                        de structures d’alternance au regard de leur projet professionnel.
                    </Text>
                    <Text>
                        Également, nous attribuons aux stagiaires un référent pédagogique en début de formation.
                        Celui-ci est en effet l’interlocuteur privilégié du stagiaire. Il s’entretient de façon
                        régulière avec celui-ci, prend des contacts avec le tuteur dans le cadre de l’alternance et
                        disponible aux différentes sollicitations du stagiaire notamment le suivi des modules et des
                        contenus de formation et la production des écrits en vue de la certification des unités
                        capitalisables.
                    </Text>
                    <Text>
                        Dans cette démarche, le tuteur de stage est l’acteur clé dans l’individualisation du parcours de
                        formation et dans la relation entre le stagiaire et l’équipe pédagogique. A cette occasion, un
                        document de référence est produit précisant les modalités d’accompagnement du stagiaire, les
                        attendus du tuteur dans cet accompagnement et les engagements de l’organisme MIFAC vis-à-vis du
                        stagiaire, du tuteur et de la structure d’accueil.
                    </Text>
                </Content>

                <Title>Le positionnement</Title>
                <Content>
                    <Text>
                        Tous les parcours de nos formations, de façon générale, démarrent par une phase de
                        positionnement. Elle est travaillée au préalable à partir du référentiel de compétences dans un
                        entretien individuel entre un membre de l’équipe pédagogique et le stagiaire. Ce positionnement
                        a pour but de repérer les capacités fortes ou faibles du stagiaire afin de proposer soit des
                        allègements de modules de formation, soit des renforcements dans les contenus ou soit une
                        individualisation de la formation. Ceux-ci seront formalisés dans la convention de formation.
                    </Text>
                    <Text>
                        Plusieurs aspects sont valorisés dans ce processus de positionnement : l’engagement mutuel et
                        réciproque, le changement du statut de candidat au statut de stagiaire de la formation
                        professionnelle, l’investissement d’un espace de parole, la reconnaissance du parcours
                        expérientiel, le repérage des points d’appui et de vigilance de la démarche formative,
                        l’objectivation de l’auto-positionnement et l’élaboration de la demande d’allègement.
                    </Text>
                    <Text>
                        D’autres points importants sont également pris en considération dans la démarche dont l’objectif
                        primaire est de permettre aux stagiaires de se situer dans l’environnement de la formation
                        vis-à-vis des professionnels du centre de formation, au sein du groupe ou de la promotion de
                        formation, dans le projet de l’alternance mais aussi de sa situation administrative au moment
                        d’entamer la formation.
                    </Text>
                </Content>
            </Container>
        </AppLayout>
    );
}

const Content = styled('div')({ display: 'flex', flexDirection: 'column', marginBottom: 32 });
