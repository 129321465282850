import styled from '@emotion/styled';
import { Button, TextField, TextFieldProps } from '@mui/material';
import React, { CSSProperties } from 'react';

import { useViewState } from '../../hooks/ViewState';
import { EmailService } from '../../services/EmailService';
import { toast } from 'react-toastify';

type ContactFormState = {
    lastName?: string;
    firstName?: string;
    phoneNumber?: string;
    email?: string;
    message?: string;
};

const isValid = (form: ContactFormState) => {
    return (
        Boolean(form.lastName) &&
        Boolean(form.firstName) &&
        Boolean(form.email) &&
        Boolean(form.phoneNumber) &&
        Boolean(form.message)
    );
};

export const ContactForm = () => {
    const [sending, setSending] = React.useState<boolean>();

    const { state, update, getState, resetState } = useViewState<ContactFormState>({
        lastName: '',
        firstName: '',
        email: '',
        phoneNumber: '',
        message: '',
    });

    const onSubmit = async (e: React.FormEvent<any>) => {
        e.preventDefault();

        const form = getState();
        if (!isValid(form)) {
            toast.error('Veuillez remplir tous les champs obligatoires du formulaire de réclamation');
        } else {
            setSending(true);
            const succes = await EmailService.sendMail(getState());
            setSending(false);
            if (succes) {
                resetState();
            }
        }
    };
    return (
        <Container>
            <Title>Envoyez-nous un message</Title>
            <Form>
                <Row>
                    <Input
                        placeholder="Nom *"
                        value={state.lastName}
                        onChange={(e) => update({ lastName: e.target.value })}
                    />
                    <Input
                        placeholder="Prénom *"
                        value={state.firstName}
                        onChange={(e) => update({ firstName: e.target.value })}
                    />
                </Row>
                <Row>
                    <Input
                        placeholder="Email *"
                        value={state.email}
                        type="email"
                        onChange={(e) => update({ email: e.target.value })}
                    />
                    <Input
                        placeholder="Numéro de téléphone *"
                        value={state.phoneNumber}
                        type="tel"
                        onChange={(e) => update({ phoneNumber: e.target.value })}
                    />
                </Row>
                <Input
                    placeholder="message"
                    multiline
                    value={state.message}
                    onChange={(e) => update({ message: e.target.value })}
                />

                <SendButton type="submit" onClick={onSubmit} disabled={sending}>
                    Envoyer
                </SendButton>

                <Help>*Les informations identifiées par un astérisque sont obligatoires.</Help>
            </Form>
        </Container>
    );
};

const Container = styled('form')({ display: 'flex', flexDirection: 'column', marginTop: 32 });
const Form = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    marginTop: 32,
    width: '50%',
    '@media (max-width: 800px)': {
        width: '100%',
    },
});
const Title = styled('h2')({
    textAlign: 'center',
    marginTop: 64,
    fontSize: 23,
    lineHeight: '30px',
    color: '#444',
    fontWeight: 'normal',
    textTransform: 'uppercase',
});
const Row = styled('div')({ display: 'flex', gap: 24, marginBottom: 12 });
const SendButton = styled(Button)({
    marginTop: 24,
    marginBottom: 24,
    width: 100,
    height: 40,
    color: '#FFF',
    background: 'orange !important',
    borderRadius: 0,
});
const Help = styled('div')({ fontSize: 14, marginBottom: 32 });

const Input = (props: TextFieldProps) => {
    const SinglelineStyle: CSSProperties = {
        borderRadius: 0,
        height: 44,
        border: '1px solid #444',
        background: '#FFF',
        padding: '0px 12px 0px 12px',
    };
    const MultilineStyle: CSSProperties = {
        ...SinglelineStyle,
        height: 150,
        textAlign: 'left',
        overflowY: 'auto',
        padding: 12,
    };
    return (
        <TextField
            {...props}
            InputProps={{ style: { padding: 0 } }}
            inputProps={{ style: props.multiline ? MultilineStyle : SinglelineStyle }}
            fullWidth
        />
    );
};
