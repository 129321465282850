import styled from '@emotion/styled';
import { AppLayout } from '../../templates/AppLayout';
import { Container, Header, Li, Text, Title, Ul } from '../../formations/FormationLayout';

import { BackNavigation } from '../../templates/BackNavigation';

export default function OutilsEtMoyensPedagogiquePage() {
    return (
        <AppLayout>
            <Container>
                <Header>
                    <BackNavigation>Nos Outils et nos moyens pédagogique</BackNavigation>
                    <Title>Nos Outils et nos moyens pédagogique</Title>
                </Header>
                <Content>
                    <Text>
                        Les stagiaires ont à leur disposition des locaux répondant aux règlements applicables aux
                        établissements recevant du public.
                    </Text>
                    <Ul>
                        <Li>
                            Les deux salles de formation sont équipées d’un vidéoprojecteur (mobile et portable) dont
                            une fait office également de salle informatique. L’Organisme MIFAC met à dispositions des
                            ordinateurs portables, équipés d’un réseau internet, à chaque fois que des interventions
                            pédagogiques nécessitent des besoins en utilisation des outils informatiques.{' '}
                        </Li>
                        <Li style={{ marginTop: 12 }}>
                            Le centre est outillée d’accessoires digitaux tels qu’une imprimante réseau, vidéo
                            projecteur. Les stagiaires ont un accès libre à l’imprimante, scanner, photocopieuse ainsi
                            que le wifi avec un code personnalisé.
                        </Li>
                        <Li style={{ marginTop: 12 }}>
                            L’espace de ressource-coworking héberge de la documentation pédagogique et d’information
                            spécialisés dans le champ de l’animation & sport est accessible par tous les apprenants. Une
                            trentaine d’ouvrages et revues sont à la disposition des stagiaires dans le cadre de leurs
                            activités de formation où ils peuvent réserver ou emprunter des références.
                        </Li>
                        <Li style={{ marginTop: 12 }}>
                            Enfin, un réseau de partenariat stable et pérenne (avec des collectivités et des structures
                            associatives à domination socioéducative ou culturelle, d’insertion sociale, de
                            développement durable et environnemental, d’économie sociale et solidaire, d’animation de
                            loisirs et d’éducation populaire) permet de faciliter les mises en situation pédagogiques en
                            alternance de nos stagiaires. Enfin, des bilans intermédiaires viennent renforcer cet
                            accompagnement pédagogique pour faire des mises au point sur les acquis du stagiaire et sur
                            les points où il faut plus apporter un regard attentionné afin de rendre cette
                            individualisation encore plus efficace.
                        </Li>
                    </Ul>
                </Content>
            </Container>
        </AppLayout>
    );
}

const Content = styled('div')({ display: 'flex', flexDirection: 'column', marginBottom: 32 });
