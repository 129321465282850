import Home from './Home';
import FormationBpjepsAPTPage from './formations/bpjeps-apt/page';
import FormationBpjepsLTPPage from './formations/bpjeps-ltp/page';
import FormationCpjepsPage from './formations/cpjeps/page';
import FormationDejepsPage from './formations/dejeps/page';
import ErrorPage from './404/404';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ContactPage from './contact/page';
import OrganismeDeFormationPage from './about/OrganismeDeFormation/page';
import LieuDeFormationPage from './about/LieuDeFormation/page';
import IndividualisationDesParcoursPage from './about/IndividualisationDesParcours//page';
import OutilsEtMoyensPedagogiquePage from './about/OutilsEtMoyensPedagogique/page';

import MentionsLegalesPage from './legales/page';

export const AppRoutes = {
    Home: '/',
    //* Qui sommes-nous ?
    OrganismeDeFormation: '/organisme-de-formation',
    LieuDeFormation: '/lieu-de-formation',
    IndividualisationDesParcours: '/individualisation-des-parcours',
    OutilsEtMoyensPedagogique: '/outils-et-moyens-pedagogique',
    //* Formations
    FormationBpjepsAPT: '/formations/bpjeps-apt',
    FormationBpjepsLTP: '/formations/bpjeps-ltp',
    FormationCpjeps: '/formations/cpjeps',
    FormationDejeps: '/formations/dejeps',

    Contact: '/contact',
    MentionsLegales: '/mentions-legales',
};

const routes = [
    {
        path: AppRoutes.Home,
        element: <Home />,
    },
    {
        path: '*', //* REDIRECT TO HOME PAGE
        element: <Home />,
    },
    //Qui sommes-nous ?
    {
        path: AppRoutes.OrganismeDeFormation,
        element: <OrganismeDeFormationPage />,
    },
    {
        path: AppRoutes.LieuDeFormation,
        element: <LieuDeFormationPage />,
    },
    {
        path: AppRoutes.IndividualisationDesParcours,
        element: <IndividualisationDesParcoursPage />,
    },
    {
        path: AppRoutes.OutilsEtMoyensPedagogique,
        element: <OutilsEtMoyensPedagogiquePage />,
    },

    //* FORMATIONS
    {
        path: AppRoutes.FormationBpjepsAPT,
        element: <FormationBpjepsAPTPage />,
        errorElement: () => <ErrorPage />,
    },
    {
        path: AppRoutes.FormationBpjepsLTP,
        element: <FormationBpjepsLTPPage />,
        errorElement: () => <ErrorPage />,
    },
    {
        path: AppRoutes.FormationCpjeps,
        element: <FormationCpjepsPage />,
        errorElement: () => <ErrorPage />,
    },
    {
        path: AppRoutes.FormationDejeps,
        element: <FormationDejepsPage />,
        errorElement: () => <ErrorPage />,
    },

    //* OTHERS
    {
        path: AppRoutes.Contact,
        element: <ContactPage />,
    },
    {
        path: AppRoutes.MentionsLegales,
        element: <MentionsLegalesPage />,
    },
];

export const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                {routes.map((item) => {
                    return <Route key={item.path} path={item.path} Component={() => item.element} />;
                })}
            </Routes>
        </BrowserRouter>
    );
};
