import styled from '@emotion/styled';
import React from 'react';
import { AppRoutes } from '../Router';

export const Footer = () => {
    return (
        <Container>
            <LegalMentions />
            <Separator />
            <Title>MIFAC - Mayotte Ingénierie Formation Audit et Conseil</Title>
        </Container>
    );
};

const Container = styled('footer')({
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'FFFFFF',
    color: '#CCCCCC',
    fontWeight: 400,
    lineHeight: 1.8,
    fontSize: 14,
    letterSpacing: 1,
    '@media (max-width: 800px)': {
        flexDirection: 'column',
    },
});

export const LegalMentions = (attrs: React.HtmlHTMLAttributes<any>) => {
    const Container = styled('a')({ textDecoration: 'none', color: '#CCCCCC' });
    return (
        <Container {...attrs} href={AppRoutes.MentionsLegales} style={{ marginRight: 6 }}>
            Mentions légales
        </Container>
    );
};

const Separator = () => {
    const Container = styled('span')({
        marginRight: 6,
        '@media (max-width: 800px)': { display: 'none' },
    });

    return <Container>|</Container>;
};

const Title = styled('span')({
    '@media (max-width: 800px)': { fontSize: 12 },
});
