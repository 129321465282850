'use client';

import React from 'react';

import { SiteMap } from './SiteMap';
import { Footer } from './Footer';
import { Header } from './Header';
import styled from '@emotion/styled';
import { Funders } from './Funders/Funders';

export const AppLayout = ({ children }: React.PropsWithChildren<any>) => {
    return (
        <Container>
            <Header />
            {children}
            <SiteMap />
            <Funders />
            <Footer />
        </Container>
    );
};

const Container = styled('main')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    background: '#FFFFFF',
    paddingTop: 60,
    color: '#000000',
});
