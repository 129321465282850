import { ArrowForward } from '@mui/icons-material';

import React from 'react';
import MenuFormations from '../formations/MenuFormations';

import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../Router';

export const Header = () => {
    return (
        <Container>
            <ButtonLink to={AppRoutes.Home}>Accueil</ButtonLink>
            <ButtonLink to={AppRoutes.OrganismeDeFormation}>
                Qui sommes-nous ?<MenuAbout />
            </ButtonLink>
            <Button>
                Formations
                <MenuFormations />
            </Button>
            <ButtonLink to={AppRoutes.Contact}>Contact</ButtonLink>
        </Container>
    );
};

const MenuAbout = () => {
    return (
        <Menu className="menu">
            <MenuLink to={AppRoutes.OrganismeDeFormation}>
                <ArrowForward /> L’Organisme de Formation
            </MenuLink>
            <MenuLink to={AppRoutes.LieuDeFormation}>
                <ArrowForward /> Lieu de formation
            </MenuLink>
            <MenuLink to={AppRoutes.IndividualisationDesParcours}>
                <ArrowForward /> Notre modèle d’individualisation des parcours
            </MenuLink>
            <MenuLink to={AppRoutes.OutilsEtMoyensPedagogique}>
                <ArrowForward /> Nos Outils et nos moyens pédagogique
            </MenuLink>
        </Menu>
    );
};

const mifacColors = {
    Orange: '#f07829',
    Blue: '#004aad',
};

const Container = styled('nav')({
    position: 'fixed',
    top: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 24,
    background: mifacColors.Blue,
    height: 60,
    zIndex: 1000,
});
const Button = styled('div')({
    color: '#FFFFFF',
    fontSize: 12,
    textDecoration: 'none',
    textTransform: 'uppercase',
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '&:hover .menu': { display: 'flex' },
});

const ButtonLink = styled(Link)({
    color: '#FFFFFF',
    fontSize: 12,
    textDecoration: 'none',
    textTransform: 'uppercase',
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',

    '&:hover .menu': { display: 'flex' },
});

const Menu = styled('div')({
    display: 'none',
    width: 500,
    flexDirection: 'column',
    background: mifacColors.Blue,
    position: 'absolute',
    padding: 0,
    top: 60,
});

const MenuLink = styled(Link)({
    height: 42,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    paddingLeft: 16,
    color: '#FFFFFF',
    '&:hover': { color: mifacColors.Blue, background: '#FFFFFF' },
});
