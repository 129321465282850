import styled from '@emotion/styled';
import { AppLayout } from '../../templates/AppLayout';
import { Container, Header, Text, Title } from '../../formations/FormationLayout';

import { BackNavigation } from '../../templates/BackNavigation';

export default function OrganismeDeFormationPage() {
    return (
        <AppLayout>
            <Container>
                <Header>
                    <BackNavigation>L’Organisme de Formation</BackNavigation>
                </Header>
                <Title>L’Organisme de Formation </Title>
                <Content>
                    <Text>
                        Crée en 2019 à l’initiative d’un jeune mahorais, MIFAC (Mayotte Ingénierie Formation Audit et
                        Conseil) est un Organisme de Formation et un Centre de Formation d’Apprentis (CFA) qui propose
                        des parcours de formation dans la filière Jeunesse et sport, du niveau III au niveau V (Cpjeps,
                        Bpjeps, Dejeps).
                    </Text>
                    <Text>
                        La création de MIFAC a été motivé par le contexte local du département mahorais maqué par de
                        nombreuses difficultés socioéconomique telles que le cout de la vie, la pression migratoire, le
                        chômage de masse, la faillite du système d’enseignement scolaire et éducatif, l’absence de
                        perspective d’avenir pour une jeunesse mahoraise en manque de repères, l’insuffisance en matière
                        d’offres d’activités de loisirs et sportives et tant d’autres cas représentant autant de
                        problématiques qui expliquaient l’instabilité territoriale.
                    </Text>
                    <Text>
                        Ainsi, une fois créée, la structure a eu comme objectif de s’investir résolument pour apporter
                        son expertise en matière d’analyse des besoins territoriaux dans les domaines de la formation
                        professionnelle et continue, de l’insertion sociale, ou par l’activité économique et la
                        recherche à la résolution des problématiques sociaux. Son rôle, serait de faire la promotion du
                        progrès social par l’émancipation des individus, la conscientisation, le développement du
                        pouvoir agir et la transformation sociale et, d’autre part encourager ; accompagner et
                        pérenniser des initiatives novatrices du développement des compétences qui tiennent compte de
                        ces réalités locales.
                    </Text>
                    <Text>
                        C’est dans cette approche que MIFAC est venu se positionner comme une alternative capable de
                        rassembler des énergies intégratives, de lutte contre les inégalités et discriminations, et
                        d’être force de propositions concrètes pour que chaque citoyen puisse se retrouver impliqué dans
                        le développement de notre territoire.
                    </Text>
                </Content>
            </Container>
        </AppLayout>
    );
}

const Content = styled('div')({ display: 'flex', flexDirection: 'column', marginBottom: 32 });
