import { Stack } from '@mui/material';
import { AppLayout } from '../../templates/AppLayout';
import {
    AccessibilitySection,
    BtnDownload,
    ButtonDownloadFormationMifac,
    ButtonDownloadRGInterieurMifac,
    DateUpdate,
    FormationContainer,
    FormationContent,
    FormationLayout,
    FormationProgramme,
    FormationProgrammeSection,
    FormationRate,
    FormationTimeSection,
    FormmationHeader,
    Li,
    Section,
    SectionDelaisAccess,
    Strong,
    Text,
    Ul,
    Underline,
} from '../FormationLayout';
import thumbnail_indicateur_BPJEPS from './../thumbnail_indicateur_BPJEPS.png';

export default function BpjepsLTPPage() {
    return (
        <AppLayout>
            <FormationContainer>
                <FormmationHeader>BPJEPS Loisirs Tous Publics (LTP)</FormmationHeader>
                <SubtitleLTP />
                <FormationRate src={thumbnail_indicateur_BPJEPS} />
                <FormationLayout style={{ marginTop: 32 }}>
                    <FormationProgramme id="FormationProgramme">
                        <FormationProgrammeSection title="Métier visé">
                            <Text>
                                La formation BPJEPS « Loisirs Tous Publics » valide une compétence dans l’encadrement
                                des activités d’animation socioéducative et culturelle. Ce diplôme permet d’intervenir,
                                dans la limite des cadres réglementaires, en autonomie dans diverses structures
                                accueillantes des publics allant de la petite enfance au public adulte.
                            </Text>
                            <Text>
                                Son titulaire conçoit des projets d’animation pédagogique adaptés aux bénéficiaires et
                                aux contextes du territoire d’intervention. Il est le responsable de ses projets du
                                point de vue de leurs mis en œuvre, de leurs initiations et animations, de leurs
                                mobilisations en ressources et de la sécurité des tiers et des personnes prises en
                                charge dans les projets activités.
                            </Text>
                            <Text>
                                Enfin, la polyvalence du diplôme donne aussi les prérogatives pour encadrer, en qualité
                                de directeur, un accueil collectif de mineurs (ACM).
                            </Text>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Exemple d’emploi préparés">
                            <Ul>
                                <Li>Animateur-directeur d’accueil collectif de mineurs (ACM)</Li>
                                <Li>Animateur secteur enfant-jeunesse- éducation</Li>
                                <Li>Animateur socioéducatif ou territorial</Li>
                                <Li>Animateur pôle famille</Li>
                                <Li>Animateur poly-activités</Li>
                                <Li>Animateur de centre de loisirs…</Li>
                            </Ul>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Secteur d’emploi ">
                            <Text>
                                La mention « Loisirs Tous Public » du BPJEPS offre un large choix de structures pour
                                exercer les fonctions d’animateur socioéducatif et culturel :
                            </Text>
                            <Ul>
                                <Li> Collectivités territoriales (services jeunesse & sports)</Li>
                                <Li>Animateur – directeur d’Accueil Collectif de mineurs</Li>
                                <Li>Animateur public personnes âgées</Li>
                                <Li>Animateur en temps périscolaire</Li>
                                <Li>Animateur secteur famille</Li>
                                <Li>
                                    Structures proposant des activités de loisirs (Centre de vacances, base de loisir,
                                    hôtellerie de plein air, etc ...)
                                </Li>
                            </Ul>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Compétences">
                            <Ul>
                                <Li>
                                    Conduire la mise en œuvre des projets d’animation socio-éducative, culturelle et
                                    sportive s’inscrivant dans le projet de la structure
                                </Li>
                                <Li>
                                    Animer le travail en équipe et partenarial des activités d’animation de découverte,
                                    d’initiation et de sensibilisation
                                </Li>
                                <Li>
                                    Organiser les ressources nécessaires pour la mise en place des animations dans la
                                    limite du cadre réglementaire et sécuritaire des activités
                                </Li>
                                <Li>
                                    Favoriser l’autonomie dans la prise en charge des différents publics accueillis dans
                                    le cadre des activités d’animation
                                </Li>
                                <Li>
                                    Former des animateurs polyvalents capables d’assurer la fonction de directeur/trice
                                    d’un accueil collectif de mineur (ACM)
                                </Li>
                                <Li>
                                    Elaborer une éthique citoyenne et engager un processus de construction d’une posture
                                    d’éducateur et animateur professionnelle
                                </Li>
                            </Ul>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Objectifs & Contenus">
                            <Section title="Bloc de compétence 1">
                                <Text>
                                    Être capable d’encadrer tout public dans tout lieu et toute structure (UC1) & Être
                                    capable de mettre en œuvre un projet d’animation s’inscrivant dans le projet de la
                                    structure (UC2).
                                </Text>
                                <Ul>
                                    <Li>
                                        Contenus UC1 / UC2 : Environnement de l’animation socioculturelle & sportive –
                                        Gestion économique & administrative – Communication & promotion – Connaissance
                                        des publics – Méthodologie et conduite de projet – Organisation d’évènements &
                                        de manifestation locale.
                                    </Li>
                                </Ul>
                            </Section>

                            <Section title="Bloc de compétence 2">
                                <Text>
                                    Être capable de conduire une action d’animation dans le champ du « loisirs tous
                                    publics » et de direction d’Accueil Collectif de Mineurs (UC3).
                                </Text>
                                <Ul>
                                    <Li>
                                        Contenus UC3 : Environnement socioéducatif & cultuel – Législation &
                                        réglementation – Direction d’un accueil collectif de mineurs (ACM).
                                    </Li>
                                </Ul>
                            </Section>

                            <Section title="Bloc de compétence 3">
                                <Text>
                                    Être capable de mobiliser les démarches d’éducation populaire pour mettre en œuvre
                                    des activités d’animation dans le champ du « loisirs tous publics » (UC4).
                                </Text>
                                <Ul>
                                    <Li>
                                        Contenus UC 4 : Notion de la pédagogie – Pratiques d’animation – Technique d’une
                                        activité (personnelle & professionnelle) – Projet d’activité – Organisation &
                                        logistique.
                                    </Li>
                                </Ul>
                            </Section>

                            <Section title="Module transversal">
                                <Text>Être capable d’accompagner les parcours de formation.</Text>
                                <Ul>
                                    <Li>
                                        Du positionnement à l’admission en formation – progressions pédagogiques
                                        centre/entreprise ; déploiement du dispositif de certification ; bilans d’étape
                                        et projet professionnel…
                                    </Li>
                                </Ul>
                            </Section>
                            <Text>
                                <Underline>Bon à savoir :</Underline> Possibilité d’individualiser le parcours de
                                formation par la voie de certification indépendante des unités capitalisables.
                            </Text>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Publics concernés">
                            <Section title="Prérequis">
                                <Ul>
                                    <Li>Être âgé de 18 ans révolus à la date d’entrée en formation</Li>
                                    <Li>
                                        Être titulaire d’une des attestations suivantes aux premiers secours (PSC1,
                                        AFPS, PSE, AFGSU, SST en cours de validité)
                                    </Li>
                                </Ul>
                            </Section>
                            <Section title="Modalités d’accès">
                                <Ul>
                                    <Li>Satisfaire l’ensemble des épreuves de sélection</Li>
                                    <Li>
                                        Avoir un niveau compréhension équivalent de la première et/ou terminale, un
                                        niveau de pratique physique et sportive correcte et un projet professionnel
                                        orienté dans l’encadrement des projet(s) d’animation à finalité socioéducative,
                                        culturelle et sportive
                                    </Li>
                                    <Li>Avoir un casier judiciaire vierge (aucune condamnation-infraction pénale)</Li>
                                    <Li>
                                        Déposer un dossier de candidature complet (obligatoire) avec les pièces
                                        justificatives demandées avant la date limite des inscriptions
                                    </Li>
                                </Ul>
                            </Section>

                            <SectionDelaisAccess />

                            <Section title="Tests de sélection">
                                <Ul>
                                    <Li>
                                        Dossier de présentation du parcours professionnel de 1½ à 2 pages joint avec le
                                        dossier d’inscription
                                    </Li>
                                    <Li>
                                        Epreuve écrite : étude de cas/texte ou questionnaires ouvertes et fermées (3h)
                                    </Li>
                                    <Li>Epreuve orale : entretien de motivation (30 minutes)</Li>
                                </Ul>
                            </Section>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Méthodes mobilisées">
                            <Text>
                                La formation est construite à partir des méthodes d’éducation active alternant cours
                                théoriques, mises en situations professionnelles et réflexions collectives. Ici nous
                                nous appuyons sur :
                            </Text>
                            <Ul>
                                <Li>Les apports théoriques et techniques</Li>
                                <Li>Les études de cas et travaux en petits ou grands groupes</Li>
                                <Li>Les vécus expérientiels et les projets des apprenants</Li>
                                <Li>Les rencontres thématiques avec des professionnels</Li>
                                <Li>
                                    Les suivis individualisés à la production des écrits et dossiers de certification
                                </Li>
                                <Li>Les moments d’échange entre le stagiaire</Li>
                                <Li>Le tuteur et référent pédagogique</Li>
                            </Ul>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Ressources mobilisées">
                            <Section title="Moyens pédagogiques">
                                <Ul>
                                    <Li>
                                        Une salle de formation équipée des tables, chaises, tableaux blanc et vidéos
                                        projecteur
                                    </Li>
                                    <Li>
                                        Une salle dédiée à un espace de ressource-coworking avec un fond documentaire et
                                        d’information (ouvrages et revues) spécialisés dans le champ professionnel de
                                        l’animation
                                    </Li>
                                    <Li>
                                        Un fond de matériels techniques nécessaires pour les besoins en quantité et en
                                        qualité de la formation
                                    </Li>
                                    <Li>
                                        A proximité : un terrain de grand jeu, un plateau sportif polyvalent et des
                                        structures d’éducation populaire (MJC, MPT, etc…)
                                    </Li>
                                    <Li>
                                        Une équipe de formateurs et d’intervenants spécialisés dans diverses thématiques
                                        dispensées dans le cadre de la formation
                                    </Li>
                                    <Li>
                                        Un double accompagnement assuré par un(e) référent(e) pédagogique et un tuteur
                                        de stage pratique
                                    </Li>
                                </Ul>
                            </Section>
                            <Section title="Autres moyens">
                                <Ul>
                                    <Li> Des bureaux administratifs (accueil et direction)</Li>
                                    <Li>Un espace de convivialité et des restaurants à proximité</Li>
                                    <Li>Des sanitaires accessibles pour les personnes à mobilité réduite</Li>
                                    <Li>Possibilité de stationnement gratuit aux environs du centre de formation</Li>
                                    <Li>
                                        Accès wifi sécurisé, imprimante et d’autres matériels à disposition des
                                        apprenants
                                    </Li>
                                </Ul>
                            </Section>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Modalités d’évaluation">
                            <Section title="Conditions d’obtention du diplôme">
                                <Text>
                                    Le BPJEPS LTP s’obtient par la capitalisation des 2 unités capitalisables
                                    transversales et des 2 unités capitalisables des spécialités. Il se fait de manière
                                    ponctuelle, c’est-à-dire qu’il n’y a pas d’examen final, mais avec un processus de
                                    certification en centre et en entreprise échelonné sur la durée du parcours de
                                    formation.
                                </Text>
                                <Text>
                                    Un programme de rattrapage est prévu en cas de non satisfaction à la certification
                                    initiale, soit après quelques semaines, soit au cours d’une session de rattrapage
                                    mise en place par l’organisme. Des validations partielles sont par ailleurs
                                    possibles pour les stagiaires n’ayant pas obtenus le diplôme afin de les permettre
                                    de garder le bénéfice des unités capitalisables en vue d’une éventuelle
                                    réinscription à la formation.
                                </Text>
                            </Section>

                            <Section title="Conditions de certification du diplôme">
                                <Text>
                                    Celles-ci sont mentionnées dans les arrêtés du 18 juillet 2016 modifié portant sur
                                    la création de la mention « loisirs tous publics (LPT) » du BPJEPS – spécialité
                                    animateur, délivré par le ministère de l’éducation nationale, de la jeunesse et des
                                    sports. Les unités capitalisables constitutives de la mention « loisirs tous publics
                                    » sont attribuées selon le référentiel de certification dont l’acquisition est
                                    contrôlée par les épreuves certificatives suivante : « production de documents
                                    écrits personnel ; entretien oral ; mise en situation professionnelle ».
                                </Text>
                            </Section>

                            <Section title="Validation des acquis de l’expérience (VAE)">
                                <Text>
                                    Pour le moment, l’organisme de formation ne propose pas un accompagnement VAE.
                                    Toutefois, une démarche de réflexion est cours d’étude pour sa faisabilité
                                    opérationnelle
                                </Text>
                            </Section>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Financement de la formation">
                            <Section title="Comment financer votre formation ?">
                                <Text>
                                    Diverses sources de financement sont possibles pour le BPJEPS APT. Elles dépendent
                                    de votre statut de candidat et peuvent-être prises en charge dans le cadre :
                                </Text>
                                <Ul>
                                    <Li>D’un financement à votre propre initiative</Li>
                                    <Li>D’un financement de votre Organisme collecteur (AKTO Mayotte)</Li>
                                    <Li>D’un plan de formation de votre employeur</Li>
                                    <Li>De votre Compte Personnel de Formation (CPF)</Li>
                                    <Li>Du plan de développement de compétences (PDC)</Li>
                                    <Li>
                                        D’un financement de votre Conseil départemental. A ce titre, des parcours sont
                                        prises en charge gratuitement pour les demandeurs d’emploi
                                    </Li>
                                </Ul>
                            </Section>
                            <Section title="Tarif de la formation ?">
                                <Text>
                                    Pour connaitre le détail de nos tarifs, veuillez nous contacter au 02 69 61 21 25 ou
                                    nous écrire au contact@mifac-ofpca.fr . Un devis détaillant les tarifs appliqués par
                                    unité capitalisable vous sera par ailleurs proposé.
                                </Text>
                            </Section>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Poursuite de parcours">
                            <Text>
                                L’obtention du diplôme BPJEPS LTP vous donne l’opportunité d’être opérationnel en tant
                                qu’animateur polyvalent et multidisciplinaire dans l’encadrement des activités
                                éducatives et sportives et/ou d’entreprendre une formation supérieure telle que le
                                DEJEPS dans les métiers de la perfection sportive et/ou de la coordination de projets,
                                territoires et réseaux.
                            </Text>
                        </FormationProgrammeSection>

                        <FormationProgrammeSection title="Rythme & durée de la formation">
                            <Text>2 050 heures sont prévues pour la formation BPJEPS - LTP : </Text>
                            <Ul>
                                <Li>850 heures d’enseignement théorique en centre de formation </Li>
                                <Li>1 200 heures de pratique professionnelle en entreprise</Li>
                                <Li>
                                    Pour une durée de 16 mois de formation avec une alternance de 1 à 2 semaines en
                                    centre et 3 à 4 semaines en entreprise
                                </Li>
                                <Li>Et un rythme de 35 heures hebdomadaire en centre et en entreprise</Li>
                            </Ul>
                            <Text>
                                Les heures liées aux épreuves de sélection ainsi que celles du positionnement ne sont
                                pas comprises dans le volume horaire total de la formation.{' '}
                            </Text>

                            <Section title="Pour votre information">
                                <Text>
                                    En cas de pertes d’heures de formation pour cas de force majeur et ne relevant pas
                                    de notre responsabilité, nous nous réservons la possibilité de les rattraper.
                                </Text>
                            </Section>
                        </FormationProgrammeSection>
                    </FormationProgramme>

                    <FormationContent id="FormationContent">
                        <BtnDownload href="/docs/formations/bpjeps/fiche_programme_BPJEPS_LTP.pdf">
                            Fiche programme BPJEPS-LTP
                        </BtnDownload>
                        <BtnDownload href="/docs/formations/bpjeps/plaquette_bpjeps_ltp.pdf">
                            Plaquette BPJEPS-LTP
                        </BtnDownload>
                        <BtnDownload href="/docs/formations/bpjeps/tableaux_equivalence_bpjeps-ltp.pdf">
                            Tableaux équivalence BPJEPS-LTP
                        </BtnDownload>

                        <ButtonDownloadFormationMifac />
                        <ButtonDownloadRGInterieurMifac />

                        <FormationTimeSection />
                        <AccessibilitySection />
                        <DateUpdate />
                    </FormationContent>
                </FormationLayout>
            </FormationContainer>
        </AppLayout>
    );
}
export const SubtitleLTP = () => {
    return (
        <Stack style={{ marginTop: 24 }}>
            <Strong>Brevet Professionnel de la Jeunesse, de l’Education Populaire et du Sport (BPJEPS),</Strong>
            <Text style={{ lineHeight: '0px' }}>Spécialité Animateur – Mention Loisirs Tous Publics (LTP)</Text>
            <Text style={{ lineHeight: '0px', marginTop: 16 }}>
                <Underline>Certificateur :</Underline> Ministère des Sports et des Jeux olympiques et paralympiques
            </Text>
            <Text style={{ lineHeight: '0px', marginTop: 16 }}>Code RNCP 28557 --- Enregistré le 10-03-201</Text>
        </Stack>
    );
};
