import styled from '@emotion/styled';
import React from 'react';
import logo from './logo.png';

export const Adress = (attrs: React.HtmlHTMLAttributes<any>) => {
    return (
        <Container {...attrs}>
            <Img src={logo} alt="logo" />
            <AddressContact style={{ marginTop: 12 }} />
            <TelContact style={{ marginTop: 24 }} />
            <MailContact style={{ marginTop: 6 }} />
        </Container>
    );
};

const mifacColors = { Orange: '#f07829', Blue: '#004aad' };
const Container = styled('div')((prop) => ({
    display: 'flex',
    flexDirection: 'column',
    background: '#FFFFFF',
    borderTop: `40px solid ${mifacColors.Orange}`,
    padding: '20px 15px ',
}));

const Img = styled('img')({ maxWidth: 300 });

export const AddressContact = (attrs: React.HtmlHTMLAttributes<any>) => {
    const Container = styled('div')({ display: 'flex', flexDirection: 'column' });
    const Street = styled('div')({ marginTop: 6 });
    const ZipcodeCity = styled('div')({ marginTop: 6 });
    const Name = styled('strong')({
        '@media (max-width: 800px)': { textAlign: 'center' },
    });

    return (
        <Container {...attrs}>
            <Name>Mayotte Ingénierie Formation Audit et Conseil (MIFAC)</Name>
            <Street>8 Rue du Receveur Madi Combo</Street>
            <ZipcodeCity>97680 Tsingoni</ZipcodeCity>
        </Container>
    );
};

export const MailContact = (attrs: React.HtmlHTMLAttributes<any>) => {
    const Container = styled('a')({ textDecoration: 'none', color: mifacColors.Blue, width: 'fit-content' });
    return (
        <Container {...attrs} href="mailto:contact@mifac-opca.fr" target="_blank" rel="noopener noreferrer">
            contact@mifac-opca.fr
        </Container>
    );
};

export const GmalMailContact = (attrs: React.HtmlHTMLAttributes<any>) => {
    const Container = styled('a')({ textDecoration: 'none', color: mifacColors.Blue, width: 'fit-content' });
    return (
        <Container {...attrs} href="mailto:mifac.ofpca@gmail.com" target="_blank" rel="noopener noreferrer">
            mifac.ofpca@gmail.com
        </Container>
    );
};

export const TelContact = (attrs: React.HtmlHTMLAttributes<any>) => {
    const Container = styled('div')({});
    return (
        <Container {...attrs}>
            <strong>Tel : </strong>02 69 61 21 25
        </Container>
    );
};
