import styled from '@emotion/styled';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

import { Container, Header } from '../formations/FormationLayout';
import { AppLayout } from '../templates/AppLayout';
import { BackNavigation } from '../templates/BackNavigation';
import { AddressContact, MailContact, TelContact } from '../templates/Adress/Adress';
import { ContactForm } from './ContactForm';

export default function ContactPage() {
    return (
        <AppLayout>
            <Container>
                <Header>
                    <BackNavigation>Contactez-nous</BackNavigation>
                </Header>
                <Title>Contactez-nous</Title>
                <Row style={{ marginTop: 64, justifyContent: 'center' }}>
                    <ContactCard>
                        <PlaceIcon style={{ width: 50, height: 50, fill: 'orange' }} />
                        <ContactTitle>ADRESSE</ContactTitle>
                        <AddressContact style={{ alignItems: 'center' }} />
                    </ContactCard>
                    <Separator />
                    <ContactCard>
                        <EmailIcon style={{ width: 50, height: 50, fill: 'orange' }} />
                        <ContactTitle>E-MAIL</ContactTitle>
                        <MailContact />
                    </ContactCard>
                    <Separator />
                    <ContactCard>
                        <SmartphoneIcon style={{ width: 50, height: 50, fill: 'orange' }} />
                        <ContactTitle>TÉLÉPHONE</ContactTitle>
                        <TelContact />
                    </ContactCard>
                </Row>
                <ContactForm />
            </Container>
        </AppLayout>
    );
}

const Title = styled('h2')({
    textAlign: 'center',
    marginTop: 64,
    fontSize: 23,
    lineHeight: '30px',
    color: '#444',
    fontWeight: 'normal',
    textTransform: 'uppercase',
});
const ContactTitle = styled('strong')({
    margin: 24,
    '@media (max-width: 1300px)': {
        textAlign: 'center',
    },
});
const ContactCard = styled('div')({
    minWidth: 300,
    display: 'flex',
    color: '#000',
    flexDirection: 'column',
    alignItems: 'center',

    '@media (max-width: 1300px)': {
        flex: 1,
        minWidth: 'unset',
    },
});
const Row = styled('div')({
    display: 'flex',
    '@media (max-width: 800px)': {
        flexDirection: 'column',
    },
});

const Separator = styled('div')({
    borderLeft: '1px solid red',
    height: 100,
    marginLeft: 32,
    marginRight: 32,
    '@media (max-width: 800px)': {
        height: 24,
        borderLeft: 'none',
    },
    '@media (max-width: 1300px)': {
        marginLeft: 16,
        marginRight: 16,
    },
});
